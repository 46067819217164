<template>
  <div>
    <HomeUser v-if="UserType !== 'Investigator'" />
    <HomeInvestigator v-else />
  </div>
</template>

<script>

import HomeUser from "@/views/home/HomeUser";
import HomeInvestigator from "@/views/home/HomeInvestigator";

export default {
  components: {
    HomeInvestigator,
    HomeUser
  },
  data() {
    return {
      UserType: "",
    }
  },
  mounted() {
    this.UserType = localStorage.getItem("userType");
  },
  created() {
    this.UserType = localStorage.getItem("userType");
  }
}
</script>

<style>

</style>
