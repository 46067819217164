<template>
  <div>
    <div>
      <h1>Active Assignments</h1>
      <div>
        <hr>
        <b-row class="mb-1">
          <b-col md="8">
            <div class="d-flex">
              <div
                class="calendar-btn-mode"
                :class="{disabled: !onLine}"
              >
                <div
                  class="img-hold"
                  :class="{disabled: !onLine}"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    size="21"
                    style="color: white"
                  />
                </div>
                <span @click="goTuIndividualSchedule()">
                  <span>View In Calendar Mode</span>
                </span>
              </div>

              <div
                v-if="onLine && isEmptyDB && UserType === 'Investigator' && rows.length"
                class="calendar-btn-mode ml-1 pl-1"
                :class="preLoad ? 'disabled' : ''"
                @click="preloadAllData()"
              >
                <span>
                  <span>Cache for Offline</span>
                </span>
              </div>

              <div
                v-if="onLine && !isEmptyDB && UserType === 'Investigator'"
                class="calendar-btn-mode ml-1 pl-1"
                :class="preLoad ? 'disabled' : ''"
                @click="syncOfflineWork()"
              >
                <span>
                  <span>Sync Offline Work</span>
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <div class="d-flex align-items-right">
                <b-form-input
                  v-model="searchFilterTerm"
                  placeholder="Filter"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          :columns="UserType === 'User' ? columnsUser : columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Subject -->
            <span v-if="props.column.field === 'ActionFileNumber'">
              <router-link
                :to="'/cases/edit-action/' + props.row.ActionID"
              >{{ props.row.ActionFileNumber }}</router-link>
              <span> ({{ props.row.ActionType }})</span>
            </span>

            <!-- Column: Subject -->
            <span v-else-if="props.column.field === 'Subject'">
              <router-link
                :to="'subject/' + props.row.SubjectID "
              >{{ props.row.Subject }}</router-link>
            </span>

            <!-- Column: Scheduled Date -->
            <span v-else-if="props.column.field === 'ScheduledDate'">
              {{ props.row.ScheduledDate | ScheduledDateFormat }}
            </span>

            <!-- Column: Time Start -->
            <span v-else-if="props.column.field === 'TimeWindowStart'">
              {{ props.row.TimeWindowStart | TimeWindowStartFormat }}
            </span>

            <!-- Column: Time Window -->
            <span v-else-if="props.column.field === 'TimeWindow'">
              {{ props.row.TimeWindowStart | TimeWindowStartFormat }} -
              {{ props.row.TimeWindowEnd | TimeWindowStartFormat }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'Action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <span
                      v-if="UserType === 'User'"
                      @click="viewCase(props.row.CaseID)"
                    >View Case</span>
                    <span
                      v-else
                      @click="viewAction(props.row.ActionID)"
                    >View Action</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                  {{ totalResultCount }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['100', '150', '200']"
                  class="mx-1"
                  @input="(value) => {
                    props.perPageChanged({ currentPerPage: value });
                    onPageSizeChanged({ currentPerPage: value });
                  }"
                />
                <span class="text-nowrap">per page</span>
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :value="1"
                  :total-rows="totalResultCount"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                  @page-click="onPageChanged"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import {BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination, BRow,} from "bootstrap-vue";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";
import {
  cacheForOffline,
  checkDatabase,
  clearCache,
  executeRequestsFromIndexedDB,
  isOldAction
} from "@core/utils/offlineService";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
  },
  filters: {
    TimeWindowStartFormat(val) {
      if (!val) return null;
      return new Date('1970-01-01T' + val + 'Z')
          .toLocaleTimeString('en-US',
              {timeZone:'UTC', hour12:true, hour:'2-digit', minute:'2-digit'}
          );
    },
    TimeWindowFormat(val) {
      if (val) {
        const time = val.split(":")[0].substr(10);
        const minutes = val.split(":")[1];
        const timePeriod = minutes + " " + val.split(":")[2].substr(3);
        const output = [time, timePeriod];
        return output.join(":")
      }
    },
    ScheduledDateFormat(val) {
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      const day = new Date(val).getDay().toString();
      const d = new Date(val).getDate().toString();
      const m = (new Date(val).getMonth() + 1).toString();
      const y = new Date(val).getFullYear();
      if (val) return days[day] + " " + (m.length === 1 ? "0" + m : m) + "/" + (d.length === 1 ? "0" + d : d) + "/" + y;
    }
  },
  data() {
    return {
      pageLength: 100,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      dir: false,
      totalResultCount: 0,
      awaitingSearch: false,
      onLine: navigator.onLine,
      isEmptyDB: true,

      preLoad: false,

      UserType: "",

      columnsUser: [
        {
          label: "Action",
          field: "ActionFileNumber",
        },
        {
          label: "Subject",
          field: "Subject",
        },
        {
          label: "City",
          field: "Location",
        },
        {
          label: "Scheduled Date",
          field: "ScheduledDate",
        },
        {
          label: "Start Time",
          field: "TimeWindowStart",
        },
        {
          label: "Case",
          field: "CaseFileNumber",
        },
        {
          label: "Action",
          field: "Action",
        },
      ],
      columns: [
        {
          label: "Action",
          field: "ActionFileNumber",
        },
        {
          label: "Subject",
          field: "Subject",
        },
        {
          label: "City",
          field: "Location",
        },
        {
          label: "Scheduled Date",
          field: "ScheduledDate",
        },
        {
          label: "Daily Instructions",
          field: "DailyInstructions",
        },
        {
          label: "Time Window",
          field: "TimeWindow",
        },
        {
          label: "Action",
          field: "Action",
        },
      ],
      rows: [],
      searchFilterTerm: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
    searchFilterTerm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadCasesPage(1);
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
    },
    isEmptyDB(val, oldVal) {
      if (val && !oldVal) {
        this.preLoad = false;
      }
    },
  },
  async mounted() {
    this.UserType = localStorage.getItem("userType");
    const _this = this;
    this.loadCasesPage(1);
    setInterval(() => {
      checkDatabase()
          .then(hasDatabase => {
            this.isEmptyDB = !hasDatabase;
            if (hasDatabase && _this.onLine) {
              this.syncOfflineWork()
            }
          })
          .catch(error => {
            console.error('Error', error);
          });
    }, 5000)
  },
  methods: {
    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.onLine = type === 'online';
    },

    syncOfflineWork() {
      if (this.preLoad) return;
      this.preLoad = true;
      executeRequestsFromIndexedDB()
    },

    async preloadAllData() {
      if (this.preLoad) return;
      const oldActions = this.rows.reduce((count, item) => count + (!isOldAction(item) ? 1 : 0), 0);
      if (oldActions === this.rows.length) {
        this.showToast("warning", "top-center", 4000, "You have no valid assignments to cache.  Only assignments up to 5 days before and 3 days in the future are cached.");
        return;
      }
      await clearCache();
      this.preLoad = true;
      this.setRowsForCache(this.rows);
      await cacheForOffline(this.rows, (result, errors) => {
        if (result) {
          this.preLoad = false;
          this.showToast("success", "top-center", 4000, "Your cases are now synced for offline usage.");
        } else {
          this.preLoad = false;
          this.showToast("danger", "top-center", 4000, "Your cases are not synced for offline usage.");
        }
        if (errors) {
          this.showToast("warning", "top-center", 4000, "Some files were unavailable to sync and are not available.");
        }
      });
    },

    setRowsForCache(rows) {
      const rowsForCache = [];
      rows.map(i => {
        const item = {
          CaseID: i.CaseID,
          ActionID: i.ActionID,
          ScheduledDate: i.ScheduledDate,
        }
        rowsForCache.push(item)
      })
      if (rowsForCache.length) sessionStorage.setItem("rowsForCache", JSON.stringify(rowsForCache))
    },

    loadCasesPage(pageNumber) {
      this.rows = [];

      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      apiService
          .get("actions/dashboard?searchFilterTerm=" +
              encodeURIComponent(this.searchFilterTerm) +
              "&skip=" +
              startIndex +
              "&max=" +
              this.pageLength
          )
          .then((response) => {
            this.loadCaseResults(response.data);
          });
    },

    loadCaseResults(responseData) {
      this.rows = responseData.Actions;
      this.totalResultCount = responseData.TotalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },

    onPageChanged(bvEvent, page) {
      this.loadCasesPage(page);
    },

    onPageSizeChanged(newPageSize) {
      this.loadCasesPage(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
    },

    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },

    viewCase(CaseID) {
      this.$router.push('/cases/' + CaseID + '/detail');
    },

    viewAction(ActionID) {
      this.$router.push('/cases/edit-action/' + ActionID);
    },

    goTuIndividualSchedule() {
      if (!this.onLine) return
      this.$router.push('/assignments/user/' + localStorage.getItem("UID"));
    },
  },
}
</script>

<style>
.calendar-btn-mode {
  display: flex;
  width: 15rem;
  background: #476dae;
  color: white;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}
.disabled {
  background: #cccfd6 !important;
  cursor: no-drop !important;
}
</style>
@/@core/utils/offlineService